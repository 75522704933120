import React from 'react';
import { ChevronRightIcon } from '@heroicons/react/solid'
import { Link } from "react-router-dom";

export default function StationListFragment({ stations, regionId, sectionId }) {

    const imageFromCKRecord = (artworkValue) => {
        return "data:image/png;base64," + artworkValue;
    };

    return (
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            {!stations && <p>Loading stations</p>}

            {stations && (stations.length === 0 || Object.keys(stations).length === 0) && <p>No stations found.</p>}

            {stations && stations.length > 0 && <ul className="divide-y divide-gray-200">
                {stations.map((item, itemIndex) => (
                    /* guid is item.recordName */
                    <li key={itemIndex}>
                        <Link to={`/app/featured/${regionId}/${sectionId}/${item.recordName}`} className="block hover:bg-gray-50">
                            <div className="flex items-center px-4 py-4 sm:px-6">
                                <div className="min-w-0 flex-1 flex items-center">
                                    <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-4 md:gap-4">
                                        <div>
                                            <p className="text-sm font-medium text-black-600 truncate">
                                                {item.fields.artwork && <img src={imageFromCKRecord(item.fields.artwork.value)} className="w-14 h-14" alt="logo" />}
                                            </p>
                                        </div>
                                        <div>
                                            {item.fields.widget &&
                                                <p className="text-sm font-medium text-black-600 truncate bg-gray-500 w-14 h-14">
                                                    <img src={imageFromCKRecord(item.fields.widget.value)} className="w-14 h-14" alt="logo" />
                                                </p>}
                                        </div>
                                        <div>
                                            <p className="text-sm font-medium text-black-600 truncate">{item.fields.title.value}</p>
                                        </div>
                                        <div>
                                            <p className="text-sm font-medium text-black-600 truncate">
                                                {item.fields.iso3166.value.map((iso, index) => (
                                                    <span key={index}>{(index ? ', ' : '') + iso}</span>
                                                ))}
                                                &nbsp; - {item.fields.iso639.value}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>
                            </div>
                        </Link>
                    </li>
                ))}
            </ul>
            }
        </div>
    )
}